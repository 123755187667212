import AppTeams from './teams';

function AppAbout() {


  return (
      <AppTeams />
  );
}

export default AppAbout;